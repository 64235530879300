import React, { useState, useContext, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { MenuType } from '../../types/entities';

import Icon, { IconSize, SvgIcon } from '@helsenorge/designsystem-react/components/Icon';
import AvatarIcon from '@helsenorge/designsystem-react/components/Icons/Avatar';
import MenuIcon from '@helsenorge/designsystem-react/components/Icons/Menu';
import SearchIcon from '@helsenorge/designsystem-react/components/Icons/Search';
import Logo from '@helsenorge/designsystem-react/components/Logo';

import { getDocumentActiveElement } from '@helsenorge/core-utils/focus-utils';
import TrapFocus from '@helsenorge/core-utils/trapfocus';
import { Breakpoint, useBreakpoint, useHover } from '@helsenorge/designsystem-react';
import { trackNavigation, trackUnreadAlert } from '@helsenorge/framework-utils/adobe-analytics';
import { getTjenesterUrl, getHelsenorgeUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';

import { globalStateContext, globalReducer } from './../../store';
import HeaderButton from './header-button';
import VarslerButton from './varsler-button';
import { getKunAntallUleste, getAntallUlesteForAlle } from '../../store/actions';
import { hasSamtykke, hasHendelsesmenyScope } from '../../utils/samtykke';
import LoginButton from '../login-button';
import LogoutButton from '../logout-button';
import Menu from '../menu';
import ProfileButton from '../profile-button';

import styles from './styles.module.scss';

interface Props {
  setMenuType: (type: MenuType) => void;
  closeMenu: () => void;
  isActive: boolean;
  isSimplified?: boolean;
  isFirstTimeLogin?: boolean;
  isAnonymous?: boolean;
  menuType?: MenuType;
  menuColor?: string;
}

export const Header: React.FC<Props> = ({
  setMenuType,
  closeMenu,
  isActive,
  isSimplified,
  isFirstTimeLogin,
  isAnonymous,
  menuType,
  menuColor,
}: Props) => {
  const subscribe = useContext(SubscribeContext);
  const globalState = useContext(globalStateContext);
  const globalDispatch = useContext(globalReducer);

  const [trapFocus, setTrapFocus] = useState<TrapFocus | undefined>(undefined);
  const [prevFocus, setPrevFocus] = useState<HTMLElement | undefined>(undefined);

  const profileMenuElementHoverObject = useHover<HTMLDivElement>();
  const profileMenuElementHoverRef = profileMenuElementHoverObject.hoverRef;

  const breakpoint = useBreakpoint();
  const isMobileOrTablet = breakpoint <= Breakpoint.md;

  const headerRef = useRef<HTMLDivElement>(null);
  const userHasSamtykke = hasSamtykke(globalState.bruker?.personverninnstillinger);
  const userHasHendelsesmenyScope = hasHendelsesmenyScope(globalState.bruker?.scopes);
  const showProfileButton = userHasSamtykke || globalState.bruker?.erRepresentasjon || globalState.bruker?.harInnsynssperre;
  const menuBlueberry = menuColor === 'blueberry';
  const menuCherry = menuColor === 'cherry';

  const headerClasses = classNames(styles.header, {
    [styles['header--no-user']]: !globalState.bruker || isSimplified,
    [styles['header--blueberry']]: menuBlueberry,
    [styles['header--cherry']]: menuCherry,
    [styles['header--unsetcolor']]: !menuCherry && !menuBlueberry,
  });
  const navigationListClasses = classNames(styles['header__navigation-list'], 'align-items-center', 'justify-content-end');
  const basicMenuClasses = classNames(styles['header__navigation-list__item']);
  const searchButtonClasses = classNames(styles['header__navigation-list__item']);

  const startTrapFocus = (node: HTMLElement): void => {
    if (trapFocus) {
      return; // Focus already trapped
    } else {
      setPrevFocus(getDocumentActiveElement(node) as HTMLElement);
      setTrapFocus(new TrapFocus(node as HTMLElement, true));
    }
  };

  const closeMenuAndReleaseFocus = (): void => {
    closeMenu();
    if (trapFocus) {
      trapFocus.deactivate();
      setTrapFocus(undefined);
      if (prevFocus) {
        prevFocus.focus();
      }
      setPrevFocus(undefined);
    }
  };

  const openCloseSetType = (event: React.MouseEvent, type: MenuType): void => {
    setMenuType(type);

    // This is when the menu goes from closed to opened
    if (!isActive) {
      // The menu has just been opened
      if (!isActive && headerRef && headerRef.current) {
        startTrapFocus(headerRef.current);
      }
    } else if (isActive && trapFocus) {
      // A menu button has been clicked while the menu is already opened
      if (type === menuType) {
        // The user is closing the menu
        closeMenuAndReleaseFocus();
      } else {
        // The user changed tab
        setPrevFocus(event.target as HTMLElement);
        (event.target as HTMLElement).focus();
      }
    }
  };

  useEffect(() => {
    if (globalState.isAuthorized) {
      if (userHasSamtykke && userHasHendelsesmenyScope) {
        getKunAntallUleste(globalDispatch, globalState.headerFooter?.header.profileMenu.errorVarslinger);
        getAntallUlesteForAlle(globalDispatch, globalState.headerFooter?.header.profileMenu.errorVarslinger);
      }
      subscribe(HeaderFooterEvents.refreshvarslinger, () => {
        if (userHasHendelsesmenyScope) {
          getKunAntallUleste(globalDispatch, globalState.headerFooter?.header.profileMenu.errorVarslinger);
          getAntallUlesteForAlle(globalDispatch, globalState.headerFooter?.header.profileMenu.errorVarslinger);
        }
      });
    }
  }, [globalState.isAuthorized, userHasSamtykke]);

  useEffect(() => {
    if (typeof globalState.antallUlesteForValgtRepresentasjon !== 'undefined' && globalState.antallUlesteForValgtRepresentasjon > 0) {
      trackUnreadAlert(globalState.antallUlesteForValgtRepresentasjon);
    }
  }, [globalState.antallUlesteForValgtRepresentasjon]);

  const handleSearchClick = (e: React.MouseEvent): void => {
    trackNavigation('søk', '', 'sidetopp');
    openCloseSetType(e, MenuType.search);
  };

  const handleLogoClick = (): void => trackNavigation('logo', '', 'sidetopp');

  const renderMenuButton = (
    menuButtonOnClick: (event: React.MouseEvent) => void,
    menuButtonMenuType: MenuType,
    menuButtonIcon: SvgIcon,
    buttonText: string
  ): React.ReactNode => {
    return (
      <>
        <HeaderButton
          icon={menuButtonIcon}
          isActive={isActive && menuType === menuButtonMenuType}
          onClick={menuButtonOnClick}
          testId={menuButtonMenuType.toString()}
        >
          {buttonText}
        </HeaderButton>
        <Menu
          headerRef={headerRef}
          menuType={menuType}
          isActive={isActive && menuType === menuButtonMenuType}
          backgroundColor={menuColor}
          onClose={closeMenuAndReleaseFocus}
        />
      </>
    );
  };

  const showVarslerInMenu = userHasSamtykke && userHasHendelsesmenyScope;
  const showSearchButton = breakpoint >= Breakpoint.md;

  return (
    <header
      ref={headerRef}
      className={headerClasses}
      aria-label={globalState.headerFooter?.header.fasteTekster.headerAriaLabel ?? 'Hovedmeny'}
      lang={globalState.language}
    >
      {isSimplified ? (
        <div className={classNames('container', styles['header__simplified-wrapper'])}>
          <div className={styles['header__logo']}>
            <span>
              <Logo color="black" />
            </span>
          </div>
          <div className={styles['header__simplified-wrapper__representasjon']}>
            {!isAnonymous && showProfileButton && (
              <>
                <Icon size={isMobileOrTablet ? IconSize.XSmall : IconSize.Small} svgIcon={AvatarIcon} color={'black'} />
                <span className={styles['header__simplified-wrapper__representasjon__text']}>{globalState.bruker?.representertBruker}</span>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={styles['header__container']}>
          <div className={styles.header__logo}>
            <a
              className={styles.header__logo__anchor}
              href={
                globalState.isAuthorized
                  ? getTjenesterUrl()
                  : getHelsenorgeUrl() + (globalState.headerFooter?.header.globalMenu.startPageUrl ?? '')
              }
              onClick={handleLogoClick}
            >
              <Logo color="black" testId="logo" />
            </a>
          </div>

          <span className={styles['flex-breakline']} />
          <ul className={navigationListClasses}>
            {!isFirstTimeLogin && (
              <>
                <li className={basicMenuClasses}>
                  {renderMenuButton(
                    (e): void => openCloseSetType(e, MenuType.basic),
                    MenuType.basic,
                    MenuIcon,
                    globalState.headerFooter.header.globalMenu.headerButtonMeny
                  )}
                </li>
                {showSearchButton && (
                  <li className={searchButtonClasses}>
                    {renderMenuButton(
                      handleSearchClick,
                      MenuType.search,
                      SearchIcon,
                      globalState.headerFooter.header.searchMenu.headerButtonSok
                    )}
                  </li>
                )}
                {showVarslerInMenu && (
                  <li className={classNames('col', styles['header__navigation-list__item'])}>
                    <VarslerButton />
                  </li>
                )}
              </>
            )}
          </ul>

          {!isAnonymous && (
            <>
              {showProfileButton ? (
                <div className={styles['header__profile-button-wrapper']} ref={profileMenuElementHoverRef}>
                  <ProfileButton
                    closeMenuAndReleaseFocus={closeMenuAndReleaseFocus}
                    headerRef={headerRef}
                    isActive={isActive && menuType === MenuType.profile}
                    menuColor={menuColor}
                    menuType={menuType}
                    openCloseSetType={openCloseSetType}
                    profileMenuElementHoverObject={profileMenuElementHoverObject}
                  />
                </div>
              ) : null}
              <div
                className={classNames(styles['header__button-right'], { [styles['header__button-right--has-user']]: showProfileButton })}
              >
                <LogoutButton headerButton />
                {!globalState.bruker && <LoginButton />}
              </div>
            </>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
