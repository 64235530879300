import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { UnmountClosed } from 'react-collapse';

import { MenuType } from '../../types/entities';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import keyCode from '@helsenorge/core-utils/constants/key-code';
import { getEventTarget } from '@helsenorge/core-utils/events';
import { KeyboardEventWithPath, MouseEventWithPath } from '@helsenorge/framework-utils/web-component/events';

import { globalStateContext } from '../../store';
import MenuBasic from '../menu-basic';
import MenuProfile from '../menu-profile';
import MenuSearch from '../menu-search';

import styles from './styles.module.scss';

export interface Props {
  headerRef?: React.RefObject<HTMLDivElement>;
  menuType?: MenuType;
  isActive?: boolean;
  backgroundColor?: string;
  onClose?: () => void;
  testId?: string;
}

const renderMenuAccordingToType = (menyType?: MenuType): React.JSX.Element | null => {
  switch (menyType) {
    case MenuType.search:
      return <MenuSearch padding />;
    case MenuType.profile:
      return <MenuProfile />;
    case MenuType.basic:
      return <MenuBasic />;
    default: {
      return null;
    }
  }
};

const Menu = (props: Props): React.JSX.Element => {
  const globalState = useContext(globalStateContext);
  const isMenuVisible = !!props.isActive;
  const isProfile = props.menuType === MenuType.profile;
  let scrollingTimeOut: number | undefined;
  const [isScrolling, setIsScrolling] = useState(false);

  const handleClickOutside = (event: MouseEventWithPath): void => {
    const target = getEventTarget(event);
    const clickedScrollbar = (event as MouseEvent).clientX ? (event as MouseEvent).clientX >= document.documentElement.offsetWidth : false;
    const clickedMacOSScrollbar = isScrolling && (target as HTMLElement).nodeName === 'HTML';

    if (
      !clickedScrollbar &&
      !clickedMacOSScrollbar &&
      props.headerRef &&
      props.headerRef.current &&
      isMenuVisible &&
      props.onClose &&
      target &&
      !props.headerRef.current.contains(target as Node)
    ) {
      props.onClose();
    }
  };

  const handleKeyDownOutside = (event: KeyboardEventWithPath): void => {
    const eventPath = (event as KeyboardEventWithPath).path || (event.composedPath && event.composedPath());
    const target = eventPath && eventPath[0] ? eventPath[0] : undefined;

    if (props.headerRef && props.headerRef.current && isMenuVisible && props.onClose && target) {
      props.onClose();
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.keyCode === keyCode.ESC) {
      event.stopPropagation();
      handleKeyDownOutside(event);
    }
  };

  const handleScroll = (): void => {
    setIsScrolling(true);
    window.clearTimeout(scrollingTimeOut);

    scrollingTimeOut = window.setTimeout(function () {
      setIsScrolling(false);
    }, 3000);
  };

  // Dette er effect for å registrere focus og click events utenfor menyen når den er åpen
  useEffect(() => {
    if (isMenuVisible) {
      document.addEventListener('scroll', handleScroll, { passive: true });
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    }

    return (): void => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const renderSection = (): React.ReactNode => {
    return (
      <section
        className={classNames(styles.menu, {
          [styles['menu--profile']]: isProfile,
          [styles['menu--active']]: isMenuVisible,
          [styles['menu--blueberry']]: props?.backgroundColor === 'blueberry',
          [styles['menu--cherry']]: props?.backgroundColor === 'cherry',
          [styles['menu--unsetcolor']]: !props?.backgroundColor,
        })}
        tabIndex={isMenuVisible ? undefined : -1}
        data-testid={props.testId}
      >
        <UnmountClosed
          theme={{
            collapse: classNames(styles['menu__collapse'], {
              [styles['menu__collapse--search']]: isMenuVisible && props.menuType === MenuType.search,
              [styles['menu__collapse--basic']]: isMenuVisible && props.menuType === MenuType.basic,
              [styles['menu__collapse--profile']]: isMenuVisible && props.menuType === MenuType.profile,
            }),
          }}
          isOpened={isMenuVisible}
        >
          {globalState?.error?.global && (
            <div className="container">
              <NotificationPanel fluid={false} variant={'error'}>
                {globalState?.error?.global}
              </NotificationPanel>
            </div>
          )}
          <div className={styles['menu__innerwrapper']} data-testid="innerwrap">
            {renderMenuAccordingToType(props?.menuType)}
          </div>
        </UnmountClosed>
      </section>
    );
  };

  return isProfile ? <div>{renderSection()}</div> : <>{renderSection()}</>;
};

export default Menu;
