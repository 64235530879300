/// <reference types="@helsenorge/framework-utils/types/hn"/>

import React from 'react';

import { AccessTokenExpirationOperationResponse, SessionTimeoutAction } from '../../types/entities';

import Modal from '@helsenorge/designsystem-react/components/Modal';

import { HeaderFooterDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import { trackError } from '@helsenorge/framework-utils/adobe-analytics';
import { hasLoggedInCookie } from '@helsenorge/framework-utils/cookie';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { HelsenorgeHttpError, getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { get, put } from '@helsenorge/framework-utils/hn-tjeneste-service';
import { setIsNavigatingToSignOut } from '@helsenorge/framework-utils/hn-user';
import { HNeventSoonAutoSignOut } from '@helsenorge/framework-utils/web-component/events';

import TimerLogic from './session-utils';

interface WarningLightboxProps {
  headerFooter?: HeaderFooterDTO;
  onShowSignOutBox?: (data: ShowSignOutBoxData) => SessionTimeoutAction;
}

interface WarningLightboxState {
  automaticSignOutWarning: boolean;
  minutes: number;
  seconds: number;
}

export interface ShowSignOutBoxData {
  tokenCountdown: number;
  tokenWarnUserWhenRemaining: number;
  tokenLifetime: number;
}

export default class WarningLightbox extends React.Component<WarningLightboxProps, WarningLightboxState> {
  private logic: TimerLogic;
  private AccessTokenExpiration = '/api/v1/AccessTokenExpiration';

  constructor(props: WarningLightboxProps) {
    super(props);
    this.logic = new TimerLogic();

    this.state = {
      automaticSignOutWarning: false,
      minutes: this.logic.getCountdownMinutes(),
      seconds: this.logic.getCountdownSeconds(),
    };
  }

  static replacePlaceholder(placeholder: string, text: string, value: string): string {
    if (!text) {
      return '';
    }

    const expression = new RegExp(placeholder, 'g');
    return text.replace(expression, value as string);
  }

  componentDidMount(): void {
    if (hasLoggedInCookie() || isAuthorized()) {
      this.logic.initializeCheckRefreshTimeout(() => {
        this.logic.initSoonAutoSignOutEvent(HNeventSoonAutoSignOut);
        get<AccessTokenExpirationOperationResponse>(this.AccessTokenExpiration)
          .then(response => response && this.handleExpirationResponse(response))
          .catch(error => this.handleError(error));
      });
    }
  }

  handleExpirationResponse = (response: AccessTokenExpirationOperationResponse): void => {
    const expiration: Date = this.getExpirationDateFromResponse(response);
    expiration.setTime(expiration.getTime() + expiration.getTimezoneOffset() * 60000);

    this.logic.initializeAutomaticSignOutWarningTimeout(expiration, this.triggerAutomaticSignOutWarning);
    this.logic.stopSignOutCountdownTimer();
    this.setState({
      automaticSignOutWarning: false,
      minutes: this.logic.getCountdownMinutes(),
      seconds: this.logic.getCountdownSeconds(),
    });
  };

  handleWarningResponse = (response: AccessTokenExpirationOperationResponse): void => {
    const expiration: Date = this.getExpirationDateFromResponse(response);
    expiration.setTime(expiration.getTime() + expiration.getTimezoneOffset() * 60000);

    const expires: number = this.logic.getMinutesUntilSessionExpire(expiration);

    let action = SessionTimeoutAction.Default;
    if (this.props.onShowSignOutBox) {
      const data: ShowSignOutBoxData = {
        tokenCountdown: window.HN?.Rest?.TokenCountdown ?? 0,
        tokenWarnUserWhenRemaining: window.HN?.Rest?.TokenWarnUserWhenRemaining ?? 0,
        tokenLifetime: window.HN?.Rest?.TokenLifetime ?? 0,
      };
      action = this.props.onShowSignOutBox(data);
    }

    switch (action) {
      case SessionTimeoutAction.RequestExtension:
        this.onExtendTicketClick();
        return;
      case SessionTimeoutAction.RequestLogout:
        this.onSignOutClick();
        return;
    }

    if (expires <= 4) {
      this.logic.intializeSignOutCountdownTimer(this.handleSignOutTick);
      this.logic.initializeAutomaticSignOutTimeout(this.triggerAutomaticSignOut);
      this.setState({
        automaticSignOutWarning: true,
      });
    } else {
      this.handleExpirationResponse(response);
    }
  };

  getExpirationDateFromResponse = (response: AccessTokenExpirationOperationResponse): Date => {
    const timeString = response.Expire as string;
    let expirationMs: number = Date.parse(timeString);
    if (!timeString.endsWith('Z')) {
      expirationMs = Date.parse(timeString + 'Z');
    }
    const expiration: Date = new Date(expirationMs);
    return expiration;
  };

  handleSignOutTick = (): void => {
    let min: number = this.state.minutes;
    let sec: number = this.state.seconds;

    if (sec === 0) {
      min--;
      sec = 59;
    } else {
      sec--;
    }

    if (min === 0 && sec === 0) {
      this.logic.stopSignOutCountdownTimer();
    }

    this.setState({
      minutes: min,
      seconds: sec,
    });
  };

  handleError = (error: unknown): void => {
    // Feilrespons fra server skal trigge utlogging, men nettverksfeil o.l. skal ikke det
    if (error instanceof HelsenorgeHttpError) {
      this.triggerAutomaticSignOut();
    }
  };

  triggerAutomaticSignOutWarning = (): void => {
    get<AccessTokenExpirationOperationResponse>(this.AccessTokenExpiration)
      .then(response => response && this.handleWarningResponse(response))
      .catch(error => this.handleError(error));
  };

  triggerAutomaticSignOut = (): void => {
    this.logic.stopTimeouts();
    setIsNavigatingToSignOut(true);
    trackError('level4', 'Inactivity');
    window.location.replace(`${getTjenesterUrl()}/Auth/AutoSignOut`);
  };

  onSignOutClick = (): void => {
    this.logic.stopTimeouts();
    setIsNavigatingToSignOut(true);
    window.location.replace(`${getTjenesterUrl()}/Auth/SignOut`);
  };

  onExtendTicketClick = (): void => {
    this.logic.stopTimeouts();
    put<AccessTokenExpirationOperationResponse>(this.AccessTokenExpiration)
      .then(response => response && this.handleExpirationResponse(response))
      .catch(error => this.handleError(error));
  };

  getTimeOutTime = (placeholder: string, text: string, value: number | undefined, appendZero = true): string => {
    let timeout = '';
    if (value === undefined) {
      return timeout;
    }

    if (value < 10 && appendZero) {
      timeout = WarningLightbox.replacePlaceholder(placeholder, text, '0' + value.toString());
    } else {
      timeout = WarningLightbox.replacePlaceholder(placeholder, text, value.toString());
    }

    return timeout;
  };

  getTimeOutString = (): string => {
    let timeout = this.getTimeOutTime(
      '{mm}',
      this.props.headerFooter?.header.fasteTekster.lightboxInactivityTimer ?? '',
      this.state.minutes
    );
    timeout = this.getTimeOutTime('{ss}', timeout, this.state.seconds);
    return timeout;
  };

  render(): React.JSX.Element | null {
    if (!this.state.automaticSignOutWarning || !this.props.headerFooter?.header.fasteTekster.lightboxInactivityTitle) {
      return null;
    }
    return (
      <Modal
        title={this.props.headerFooter?.header.fasteTekster.lightboxInactivityTitle ?? ''}
        noCloseButton
        description={`${this.props.headerFooter?.header.fasteTekster.lightboxInactivityDescription}

        ${this.getTimeOutString()}
        
        ${this.props.headerFooter?.header.fasteTekster.lightboxInactivityDoYouWantToStay}`}
        primaryButtonText={this.props.headerFooter?.header.fasteTekster.lightboxInactivityButtonYes ?? ''}
        secondaryButtonText={this.props.headerFooter?.header.fasteTekster.lightboxInactivityButtonSignOut ?? ''}
        onSuccess={this.onExtendTicketClick}
        onClose={this.onSignOutClick}
      />
    );
  }
}
