import React, { useState, useEffect, useContext } from 'react';

import { SessionTimeoutAction } from '../../types/entities';

import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';

import { createCrossDomainIFrame } from './session-utils';
import WarningLightbox, { ShowSignOutBoxData } from './warning-lightbox';
import { globalStateContext } from '../../store';

interface Props {
  isAuthorized: boolean;
  testId?: string;
}

const Session: React.FC<Props> = ({ isAuthorized, testId }: Props) => {
  const subscribe = useContext(SubscribeContext);
  const globalState = useContext(globalStateContext);

  const [onShowSignOutBox, setOnShowSignOutBox] = useState<(data: ShowSignOutBoxData) => SessionTimeoutAction>();

  useEffect(() => {
    subscribe(HeaderFooterEvents.setonshowsignoutbox, (event: CustomEvent) => {
      setOnShowSignOutBox(() => event.detail.onShowSignOutBox);
    });
  }, []);

  useEffect(() => {
    if (!erTjenester() && isAuthorized) {
      createCrossDomainIFrame();
    }
  }, [isAuthorized]);

  return (
    <div data-testid={testId}>
      <WarningLightbox headerFooter={globalState.headerFooter} onShowSignOutBox={onShowSignOutBox} />
    </div>
  );
};

export default Session;
