import { GlobalState } from '../store/initialState';

/**
 * Finn antall uleste for bruker
 * @param userGuid Valgt brukers id
 * @param globalState Global state
 * @returns antall uleste for bruker eller null om det ikke finnes
 */
export const antallUlesteForUser = (userGuid: string, globalState: GlobalState): number | undefined => {
  return globalState.antallUlesteForAlleRepresentasjoner?.find(antallUleste => antallUleste.profilId === userGuid)?.antall ?? undefined;
};
