import React, { useEffect, useState } from 'react';

import cn from 'classnames';

import Button from '@helsenorge/designsystem-react/components/Button';
import Close from '@helsenorge/designsystem-react/components/Close';
import Title from '@helsenorge/designsystem-react/components/Title';
import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';
import useFocusTrap from '@helsenorge/designsystem-react/hooks/useFocusTrap';
import { useUuid } from '@helsenorge/designsystem-react/hooks/useUuid';

import styles from './styles.module.scss';

export interface AppInvitationProps {
  /** Title text */
  title: string;
  /** Description text */
  description: string;
  /** The primary button text */
  primaryButtonText: string;
  /** Secondary button text */
  secondaryButtonText: string;
  /** Close button label */
  closeButtonLabel: string;
  /** The callback for handling closing the modal */
  onClose: () => void;
  /** The callback for handling opening the app */
  onAppOpen: () => void;
  /** The callback for handling regular login behaviour */
  onWebLogin: () => void;
}

const AppInvitation: React.FC<AppInvitationProps> = (props: AppInvitationProps) => {
  const { title, description, primaryButtonText, secondaryButtonText, closeButtonLabel, onAppOpen, onClose, onWebLogin } = props;
  const [showDialog, setShowDialog] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const dialogRef = React.useRef<HTMLDivElement>(null);
  useFocusTrap(dialogRef, true);
  const titleId = `app-invitation-title-${useUuid()}`;
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint <= Breakpoint.xs;
  const isFluidButton = isMobile ? true : false;

  const handleClose = (): void => {
    setIsClosing(true);

    // The animation duration is 200ms
    setTimeout(() => {
      setShowDialog(false);
    }, 200);
  };

  useEffect(() => {
    if (!showDialog) {
      onClose();
    }
  }, [showDialog]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    dialogRef.current?.focus();

    return (): void => {
      document.body.style.overflow = '';
    };
  }, []);

  const Component = (
    <div data-testid="dialog-container">
      <div
        ref={overlayRef}
        className={cn(styles['modal-overlay'], {
          [styles['modal-overlay--fade-in']]: !isClosing,
          [styles['modal-overlay--fade-out']]: isClosing,
        })}
      >
        <div
          className={cn(styles.modal, { [styles['modal--slide-in']]: !isClosing, [styles['modal--slide-out']]: isClosing })}
          role="dialog"
          aria-labelledby={titleId}
          aria-modal="true"
          tabIndex={-1}
          ref={dialogRef}
        >
          <div className={cn(styles['modal__row'])}>
            <div className={cn(styles['modal__col'])}>
              <div className={cn(styles['modal__close-wrapper'])}>
                <Close onClick={handleClose} ariaLabel={closeButtonLabel} />
              </div>
              <Title id={titleId} htmlMarkup="h1" appearance={'title2'}>
                {title}
              </Title>
              <p className={styles.modal__description}>{description}</p>
              <div className={styles['modal__actions-wrapper']}>
                {primaryButtonText && primaryButtonText.length > 0 && (
                  <Button fluid={isFluidButton} textPosition={'centered'} onClick={onAppOpen}>
                    {props.primaryButtonText}
                  </Button>
                )}
                {secondaryButtonText && secondaryButtonText?.length > 0 && (
                  <Button fluid={isFluidButton} textPosition={'centered'} onClick={onWebLogin} variant="outline">
                    {secondaryButtonText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return showDialog ? <div>{Component}</div> : null;
};

export default AppInvitation;
