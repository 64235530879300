const allowConsoleOutput = () => !['production', 'test'].includes(process.env.NODE_ENV ?? '');
const log = (message, ...optionalParams) => {
    if (!allowConsoleOutput())
        return;
    // eslint-disable-next-line no-console
    console.log(message, optionalParams);
};
/**
 * Returnerer document.activeElement (element in focus), uavhengig av om den er i document-dom rllrt shadow-dom
 * @param element HtmlElement som den skal søke i
 * @param logCallback Funksjon som om det oppstår en feil. Brukes for å logge til server.
 */
export const getDocumentActiveElement = (element, logCallback) => {
    try {
        const domNode = typeof element === 'string' ? document.querySelector(element) : element;
        const root = domNode?.getRootNode();
        return root?.activeElement;
    }
    catch (e) {
        const logger = logCallback ?? log;
        logger('Feil ved å ta tak i active element basert på angitt node: ', element, e);
        return null;
    }
};
