import React, { useEffect, useContext, useState } from 'react';

import { useLocation, Location, useNavigate, BrowserRouter, Routes, Route } from 'react-router-dom';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import Title from '@helsenorge/designsystem-react/components/Title';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { trackRepresentation } from '@helsenorge/framework-utils/adobe-analytics';
import { setCookie } from '@helsenorge/framework-utils/cookie';
import { getUserLanguage } from '@helsenorge/framework-utils/hn-language';
import { getPath } from '@helsenorge/framework-utils/hn-page';
import { erHelsenorge, erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { getVisPersonvelger } from '@helsenorge/framework-utils/hn-user';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import {
  HNeventSetVisPersonvelger,
  RedirectToDetail,
  RedirectToUrlAfterLoginDetail,
} from '@helsenorge/framework-utils/web-component/events';
import WithStore from '@helsenorge/framework-utils/web-component/with-store';

import { globalStateContext, globalReducer } from '../../store';
import {
  getUser,
  erInnloggetOgErTjenester,
  DispatchAction,
  setIsListenerReady,
  getRepresentasjonsforhold,
  getHeaderFooter,
  getAntallUlesteForAlle,
  getKunAntallUleste,
} from '../../store/actions';
import { GlobalState, initialState } from '../../store/initialState';
import { reducer } from '../../store/reducers';
import PersonvelgerListe from '../personvelger-liste';

import styles from './styles.module.scss';

export const Personvelger = (): React.JSX.Element | null => {
  const subscribe = useContext(SubscribeContext);
  const globalState = useContext(globalStateContext);
  const globalDispatch = useContext(globalReducer);

  const [redirectTo, setRedirectTo] = useState<string>();
  const [redirectToUrlAfterLogin, setRedirectToUrlAfterLogin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Effect run on init
  useEffect(() => {
    if (window && window.HN) {
      const userLanguage = getUserLanguage() || LanguageLocales.NORWEGIAN;
      getHeaderFooter(globalDispatch, 'Det oppstod en feil ved henting av menyen.', userLanguage);
      erInnloggetOgErTjenester(globalDispatch);

      subscribe(HeaderFooterEvents.setredirectto, (event: CustomEvent<RedirectToDetail>) => {
        setRedirectTo(event.detail.redirectTo);
      });
      /** @deprecated Slett når alle har tatt i bruk v31 */
      subscribe(HeaderFooterEvents.setfromlocation, (event: CustomEvent<{ fromLocation: Location }>) => {
        setRedirectTo(event.detail.fromLocation.pathname);
      });

      subscribe(HeaderFooterEvents.setredirecttourlafterlogin, (event: CustomEvent<RedirectToUrlAfterLoginDetail>) => {
        setRedirectToUrlAfterLogin(event.detail.redirectToUrlAfterLogin);
      });
    }
  }, []);

  const getUserData = (): void => {
    if (globalState.headerFooter) {
      getUser(globalDispatch, globalState.headerFooter.header.personvelger?.errorHentBruker ?? 'Feil under henting av bruker');
      getRepresentasjonsforhold(
        globalDispatch,
        globalState.headerFooter.header.personvelger?.errorRepresentasjoner ?? 'Feil under henting av representasjonsforhold'
      );
      getKunAntallUleste(
        globalDispatch,
        globalState.headerFooter.header.profileMenu?.errorVarslinger ?? 'Det har skjedd en feil. Varslingene kan ikke vises.'
      );
      getAntallUlesteForAlle(
        globalDispatch,
        globalState.headerFooter.header.profileMenu?.errorVarslinger ?? 'Det har skjedd en feil. Varslingene kan ikke vises.'
      );
    }
  };

  // Effect for getting user data
  useEffect(() => {
    if (globalState.isAuthorized) {
      getUserData();

      subscribe(HeaderFooterEvents.setlistenerready, () => {
        setIsListenerReady(globalDispatch);
      });
    }
  }, [globalState.isAuthorized, globalState.headerFooter]);

  useEffect(() => {
    const users = globalState.representasjoner?.andreRepresentasjoner;

    if (erHelsenorge() && users?.length === 1) {
      setCookie('HN-ValgtRepresentasjon', '0');
      HNeventSetVisPersonvelger(false);
    }
    if (users && users.length > 1 && getVisPersonvelger() && location.pathname !== '/velg-person') {
      navigate('/velg-person');
    }
  }, [globalState.representasjoner?.andreRepresentasjoner, location]);

  useEffect(() => {
    if (globalState.error?.representasjonsforholdError && erHelsenorge()) {
      HNeventSetVisPersonvelger(false);
    }
  }, [globalState.error?.representasjonsforholdError]);

  const handleRedirect = (newPersonHasBeenSelected: boolean): void => {
    if (redirectToUrlAfterLogin && redirectTo) {
      // Vertikalen har satt redirectToUrlAfterLogin til true og har satt redirectTo
      // til en URL de vil redirecte til
      window.location.replace(redirectTo);
    } else if (!newPersonHasBeenSelected && redirectTo) {
      // Vertikalen har satt redirectTo til en URL de vil redirecte til så lenge
      // bruker velger samme person som er valgt fra før
      window.location.replace(redirectTo);
    } else if (!newPersonHasBeenSelected) {
      if (!globalState.bruker?.erRepresentasjon) {
        trackRepresentation('Eget bruk', 'Eget bruk');
      }
      // Bruker har valgt samme person som er valgt fra før, da kan vi skjule personvelger og vise vertikalen
      HNeventSetVisPersonvelger(false);
      if (erTjenester()) {
        navigate('/');
      }
    } else if (erHelsenorge()) {
      // Bruker har valgt å representere en ny person, men siden vi
      // er på åpne sider kan vi bare laste samme side på på nytt
      window.location.reload();
    } else {
      // Bruker har valgt å representere en ny person, da må vi gå til forsiden
      // fordi det kan være at den nye personen ikke har tilgang til tjenesten
      window.location.replace('/');
    }
  };

  const titleId = 'personvelger-title';

  return (
    <div className={styles['personvelger-wrapper']}>
      {globalState.headerFooter && !globalState.loading?.global ? (
        <section aria-labelledby={titleId}>
          <Title htmlMarkup={'h1'} appearance={'title1'} margin={1} id={titleId}>
            {globalState.headerFooter?.header.personvelger?.personvelgerHeader}
          </Title>
          <PersonvelgerListe hideSelectedOnStartup={true} afterPersonValgt={handleRedirect} />
          <p>{globalState.headerFooter?.header.personvelger?.personvelgerIngress}</p>
        </section>
      ) : (
        <Loader className={styles['personvelger-loader']} />
      )}
    </div>
  );
};

const props = {
  StateContext: globalStateContext,
  DispatchContext: globalReducer,
  reducer: reducer,
  initialState: initialState,
};

const PersonvelgerWithProps: React.FC = () => {
  return (
    <WithStore<GlobalState, DispatchAction> {...props}>
      <BrowserRouter basename={getPath()}>
        <Routes>
          <Route path="*" element={<Personvelger />} />
        </Routes>
      </BrowserRouter>
    </WithStore>
  );
};

export default PersonvelgerWithProps;
