import { useContext } from 'react';

import { PersonvelgerElement } from '../../types/entities/representasjon.v1entities';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import PortableText from '@helsenorge/core-cms/richtext';

import PersonvelgerItem from './personvelger-item';
import { globalStateContext } from '../../store';
import { OnSetRepresentasjonForholdCallback } from '../../store/actions';

import './styles.scss';

export interface PersonvelgerRoutingProps {
  afterPersonValgt: OnSetRepresentasjonForholdCallback;
}

export interface PersonvelgerProps extends PersonvelgerRoutingProps {
  hideSelectedOnStartup?: boolean;
}

const PersonvelgerListe = ({ hideSelectedOnStartup: hideSelected = false, afterPersonValgt }: PersonvelgerProps): React.JSX.Element => {
  const globalState = useContext(globalStateContext);

  const renderRepresentasjoner = (): React.JSX.Element => {
    const changedUser = globalState.representasjoner?.changedUser;

    const isSelected = (person: PersonvelgerElement): boolean =>
      person.profilGuid === globalState.representasjoner?.currentRepresentasjon?.profilGuid;

    if (globalState.loading?.isRepresentasjonsforholdLoading) {
      return <Loader key={0} testId="representasjoner-loader" />;
    } else {
      return (
        <ul className="representasjoner-list">
          {globalState.representasjoner?.andreRepresentasjoner?.map(user => (
            <PersonvelgerItem
              key={user.profilGuid}
              user={{ ...user, selected: isSelected(user) }}
              hideSelected={hideSelected && !changedUser}
              afterPersonValgt={afterPersonValgt}
            />
          ))}
        </ul>
      );
    }
  };

  return (
    <>
      {globalState?.error?.representasjonsforholdError && (
        <>
          <NotificationPanel variant={'error'}>{globalState?.error?.representasjonsforholdError}</NotificationPanel>
          <Spacer />
        </>
      )}
      {globalState?.error?.profileError && (
        <>
          <NotificationPanel variant={'error'}>{globalState?.error?.profileError}</NotificationPanel>
          <Spacer />
        </>
      )}
      {globalState.headerFooter?.header.personvelger?.representasjonerBarn16Plus &&
        (globalState.bruker?.harBarnOver11 || globalState.bruker?.harBarnOver16) && (
          <>
            <NotificationPanel variant={'info'}>
              <PortableText value={globalState.headerFooter?.header.personvelger?.representasjonerBarn16Plus} />
            </NotificationPanel>
            <Spacer />
          </>
        )}
      {(globalState.bruker?.harBarnOver11 || globalState.bruker?.harBarnOver16 || globalState.bruker?.harBarnMedAnnenAdresse) && <Spacer />}
      {globalState.headerFooter?.header.personvelger?.representasjonerManglendeForeldreansvar &&
      globalState.bruker?.harBarnUnder16UtenForeldreansvar ? (
        <>
          <NotificationPanel variant={'info'}>
            <PortableText value={globalState.headerFooter?.header.personvelger?.representasjonerManglendeForeldreansvar} />
          </NotificationPanel>
          <Spacer />
        </>
      ) : (
        globalState.headerFooter?.header.personvelger?.representasjonerBarnAnnenAdresse &&
        globalState.bruker?.harBarnMedAnnenAdresse && (
          <>
            <NotificationPanel variant={'info'}>
              <PortableText value={globalState.headerFooter?.header.personvelger?.representasjonerBarnAnnenAdresse} />
            </NotificationPanel>
            <Spacer />
          </>
        )
      )}

      {!globalState?.error?.profileError && renderRepresentasjoner()}
    </>
  );
};

export default PersonvelgerListe;
