import React, { useContext, useMemo } from 'react';

import classNames from 'classnames';

import { PersonvelgerElement } from '../../types/entities/representasjon.v1entities';
import { RepresentasjonsforholdType } from '../../types/entities/representasjon.v1enums';

import Avatar from '@helsenorge/designsystem-react/components/Avatar';
import Badge from '@helsenorge/designsystem-react/components/Badge';

import { globalStateContext, globalReducer } from '../../store';
import { setRepresentasjonForhold } from '../../store/actions';
import { antallUlesteForUser } from '../../utils/antallUleste';

import { PersonvelgerRoutingProps } from './';

interface Props extends PersonvelgerRoutingProps {
  user: PersonvelgerElement;
  hideSelected?: boolean;
  antallUleste?: number;
}

const PersonvelgerItem = (props: Props): React.JSX.Element => {
  const globalState = useContext(globalStateContext);
  const globalDispatch = useContext(globalReducer);

  const antallUleste = useMemo(
    () =>
      props.user.selected
        ? (globalState.antallUlesteForValgtRepresentasjon ?? 0)
        : (antallUlesteForUser(props.user.profilGuid, globalState) ?? 0),
    [globalState.antallUlesteForValgtRepresentasjon, globalState.antallUlesteForAlleRepresentasjoner]
  );

  const setUser = (event: React.MouseEvent): void => {
    event.preventDefault();

    setRepresentasjonForhold(
      globalDispatch,
      globalState.headerFooter?.header.personvelger?.errorValgtPerson ?? 'Det oppstod en feil da du valgte denne personen.',
      props.user,
      props.afterPersonValgt
    );
  };

  const buttonClasses: string = classNames({
    'representasjoner-list__item__button': true,
    'representasjoner-list__item__button--selected': !props.hideSelected && props.user.selected,
  });

  let representasjonsType: string;

  switch (props.user.representasjonsforholdType) {
    case RepresentasjonsforholdType.innloggetBruker:
      representasjonsType = globalState.headerFooter?.header.personvelger?.headerMenuProfileRepresentasjonerDegSelv ?? '';
      break;
    case RepresentasjonsforholdType.foreldreansvar:
      representasjonsType = globalState.headerFooter?.header.personvelger?.headerMenuProfileRepresentasjonerDuHarForeldreansvar ?? '';
      break;
    case RepresentasjonsforholdType.fullmakt:
      representasjonsType = globalState.headerFooter?.header.personvelger?.headerMenuProfileRepresentasjonerDuHarFullmakt ?? '';
      break;
    default:
      representasjonsType = '';
  }

  return (
    <li className={'representasjoner-list__item'}>
      <button type="button" className={buttonClasses} onClick={setUser}>
        <div className="representasjoner-list__item__avatar" aria-hidden>
          <Avatar selected={!props.hideSelected && props.user.selected}>{props.user.fulltNavn}</Avatar>
        </div>

        <p className="representasjoner-list__item__text">
          <strong className="representasjoner-list__item__name">{props.user.fulltNavn}</strong>
          {representasjonsType && <span className="representasjoner-list__item__separator">{` - `}</span>}
          {representasjonsType && <span className="representasjoner-list__item__representasjonstype">{representasjonsType}</span>}
        </p>
        {!!antallUleste && <Badge color="cherry">{antallUleste}</Badge>}
      </button>
    </li>
  );
};

export default PersonvelgerItem;
