import React, { useEffect, useContext } from 'react';

import { setDefaultOptions } from 'date-fns';
import { enGB, se } from 'date-fns/locale';

import ExpanderList from '@helsenorge/designsystem-react/components/ExpanderList';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import Avatar from '@helsenorge/designsystem-react/components/Icons/Avatar';
import Settings from '@helsenorge/designsystem-react/components/Icons/Settings';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import fontStyles from '@helsenorge/designsystem-react/scss/typography.module.scss';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { initialize, safeFormatCET, DateFormat } from '@helsenorge/core-utils/date-fns-utils';
import { useBreakpoint, Breakpoint } from '@helsenorge/designsystem-react';
import { getErInnloggetViaHnAppUngdom } from '@helsenorge/framework-utils/hn-user';

import MenuProfileLinks from './menu-profile-links';
import { globalReducer, globalStateContext } from '../../store';
import { getRepresentasjonsforhold } from '../../store/actions';
import { hasSamtykke } from '../../utils/samtykke';
import LogoutButton from '../logout-button';
import PersonvelgerListe from '../personvelger-liste';

import styles from './styles.module.scss';

const transformLastLogonFormat = (time: string, locale?: LanguageLocales): string => {
  if (locale === LanguageLocales.ENGLISH) {
    setDefaultOptions({ locale: enGB });
  } else if (locale === LanguageLocales.SAMI_NORTHERN) {
    setDefaultOptions({ locale: se });
  } else {
    initialize();
  }

  return safeFormatCET(time, DateFormat.LongDateTime);
};

const MenuProfile: React.FC = () => {
  const globalState = useContext(globalStateContext);
  const breakpoint = useBreakpoint();
  const globalDispatch = useContext(globalReducer);
  const isMobileOrTablet = breakpoint === Breakpoint.xs || breakpoint === Breakpoint.sm || breakpoint === Breakpoint.md;
  const [personvelgerListeExpanded, setPersonvelgerExpanded] = React.useState(false);
  const userHasSamtykke = hasSamtykke(globalState.bruker?.personverninnstillinger);

  useEffect(() => {
    if (globalState.isAuthorized && globalState.headerFooter && personvelgerListeExpanded) {
      getRepresentasjonsforhold(
        globalDispatch,
        globalState.headerFooter.header.personvelger?.errorRepresentasjoner ?? 'Feil under henting av representasjonsforhold'
      );
    }
  }, [globalState.isAuthorized, globalState.headerFooter, personvelgerListeExpanded]);

  const handleRedirect = (newPersonHasBeenSelected: boolean): void => {
    if (newPersonHasBeenSelected) {
      window.location.replace('/');
    }
  };

  const renderSistInnlogget = (): React.JSX.Element | undefined => {
    if (globalState.bruker?.sistInnlogget) {
      return (
        <p className={styles.sistinnlogget}>
          {globalState.headerFooter.header.profileMenu.headerMenuProfileSistInnlogget}{' '}
          {transformLastLogonFormat(globalState.bruker?.sistInnlogget, globalState.language)}
        </p>
      );
    }
  };

  if (getErInnloggetViaHnAppUngdom()) {
    return (
      <div data-testid="menu-profile">
        <LogoutButton />
        <Spacer size={'m'} />
        {renderSistInnlogget()}
      </div>
    );
  }

  return (
    <div className={styles['menu-profile']} data-testid="menu-profile">
      <ExpanderList color="blueberry" childPadding={false}>
        <ExpanderList.Expander
          icon={<Icon size={IconSize.XSmall} svgIcon={Avatar} />}
          title={globalState.headerFooter.header.profileMenu.headerMenuProfileRepresentasjonerTitle}
          className={isMobileOrTablet ? fontStyles.title4 : fontStyles.title3}
          onExpand={(): void => setPersonvelgerExpanded(true)}
        >
          {personvelgerListeExpanded && (
            <>
              <Spacer size={'s'} />
              <PersonvelgerListe afterPersonValgt={handleRedirect} />
              <Spacer size={'xl'} />
            </>
          )}
        </ExpanderList.Expander>
        {userHasSamtykke && (
          <ExpanderList.Expander
            icon={<Icon svgIcon={Settings} />}
            title={globalState.headerFooter.header.profileMenu.headerMenuProfileLinksTitle}
            className={isMobileOrTablet ? fontStyles.title4 : fontStyles.title3}
          >
            <Spacer size={'s'} />
            <MenuProfileLinks />
            <Spacer size={'xl'} />
          </ExpanderList.Expander>
        )}
      </ExpanderList>
      <div>
        {globalState.bruker?.sistInnlogget && renderSistInnlogget()}
        <LogoutButton />
      </div>
    </div>
  );
};

export default MenuProfile;
